.datacater-pipeline-designer-pipeline-steps-list {
  padding-left: 50px !important;

  .datacater-pipeline-designer-pipeline-steps-list-delete-zone {
    background-color: #E8625E;
    color: #fff;
    height: calc(4rem + 2px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .datacater-pipeline-designer-pipeline-steps-list-delete-zone.hovering {
    background-color: #CC3A38;
  }

  ul.list-group {
    height: 100%;
    margin-right: 12px;
  }

  .list-group-item:not(:last-child) {
    &:after {
      content: '';
      border-bottom: 1px solid #eaf6ec;
      position: absolute;
      top: 1.25rem;
      width: 1rem;
    }

  }

  .list-group-item.active-step {
    .avatar-title {
      background-color: #4fb35d !important;
      color: #fff !important;
    }
  }

  .list-group-item.dragged-step {
    .avatar-title {
      border: 1px dashed #4fb35d ;
      opacity: 0.7;
    }
  }
}
