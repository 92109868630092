$table-prefix: datacater-grid;
$row-hovered-background-color: #f5f5f5;

@keyframes datacater-animation-fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@import '~react-base-table/es/BaseTable.scss';

.datacater-context-bar-active {
  .#{$table-prefix} {
    &-container {
      /* screen width - left sidebar width - context bar width + border */
      width: calc(100vw - 66px - var(--datacater-context-bar-width));
    }
  }
}

@media only screen and (min-width: 1400px) {
  .datacater-context-bar-active {
    .#{$table-prefix} {
      &-container {
        /* screen width - left sidebar width - context bar width + border */
        width: calc(100vw - 66px - var(--datacater-context-bar-width-large-screen));
      }
    }
  }
}

.datacater-grid--empty .datacater-grid__body div {
  height: 100% !important;
}

.#{$table-prefix} {
  background-color: #ffffff;

  &__empty-layer {
    display: none;
  }

  &-container {
    /* screen width - left sidebar width + border */
    width: calc(100vw - var(--datacater-pipeline-designer-navigation-width));
    height: calc(100vh - 172px - 3.5rem);
  }

  &__table {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.65rem;
  }
  &__header-row,
  &__row {
    border-bottom: none;
  }
  &__row {
    .datacater-grid-row-is-scrolling {
      height: 100%;
      width: 100%;
    }
  }

  &__header-cell {
    background-color: #fff;
    border-bottom: 2px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 2px solid #ccc;
    padding: 0;

    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    font-weight: 500;

    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    flex-direction: column!important;
    display: -webkit-box!important;
    display: flex!important;

    .datacater-stats-content {
      font-family: 'Roboto Mono', monospace;
      font-weight: 400;
      font-size: 0.65rem;
    }
  }

  &__table-main &__header-cell:last-child,
  &__table-main &__row-cell:last-child {
    padding-right: 0 !important;
  }

  &__header-cell:first-child &__header-cell-text {
    writing-mode: vertical-lr;
    text-orientation: revert;
    transform: rotate(180deg);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    position: absolute;
    font-size: 0.9rem;
    font-weight: 400;
  }

  &__header-cell-text {
    height: 100%;
    width: 100%;

    .sample-cell {
      border-bottom: 1px solid #ccc;
      margin: 0 !important;
      width: 100%;
    }

    .datacater-source-logo {
      display: none;
      height: 15px;
    }

    .datacater-source-id {
      color: #ccc;
      display: none;
      font-weight: 400;
    }
  }

  &__header-cell:hover {
  }

  &__table-frozen-left &__row-cell {
    background-color: #eee;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    justify-content: center;
    padding: 0 !important;
  }

  &__table-frozen-left &__header-cell {
    background-color: #eee;
    border-right: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
  }

  &__table-frozen-left &__row-cell-text {
    text-align: center;
    width: 100%;
  }

  &__table-frozen-left {
    .datacater-grid__header-cell {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__row-cell {
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 0;

    .sample-cell {
      //animation: datacater-animation-fade-in 0.1s ease-in;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 7.5px;
    }

    .sample-cell.changed-in-current-step {
      background-color: #b8e0be;
    }

    .sample-cell.changed-in-previous-step {
      background-color: #dff1e1;
    }
  }

  &-filters &__table-main &__row-cell,
  &-pipeline-steps &__table-main &__row-cell {
    cursor: pointer;
  }

  @for $i from 0 through 100 {
    &.hovered-col-#{$i}.datacater-grid-filters [data-col-idx="#{$i}"],
    &.hovered-col-#{$i}.datacater-grid-pipeline-steps [data-col-idx="#{$i}"] {
      background-color: rgba(219, 239, 222, 0.49);
    }

    &.hovered-col-#{$i} &__header-row [data-col-idx="#{$i}"]{
      background-color: #ddd;
    }

    &.active-col-#{$i} [data-col-idx="#{$i}"]{
      border-left: 2px solid #4FB35D;
      border-right: 2px solid #4FB35D;
    }

    &.active-col-#{$i} &__header-cell[data-col-idx="#{$i}"] .datacater-grid__header-cell-text {
      padding-left: calc(0.375rem - 2px) !important;
      padding-right: calc(0.375rem - 1px) !important;
    }

    &.active-col-#{$i} &__row-cell[data-col-idx="#{$i}"] .sample-cell {
      padding: 0 5.5px;
    }

    &.active-col-#{$i} &__header-cell[data-col-idx="#{$i}"]:hover .datacater-grid__column-resizer {
      display: none !important;
    }

    &.active-col-#{$i} &__header-row [data-col-idx="#{$i}"]{
      //background-color: #ddd;
      border-top: 2px solid #4FB35D;
      padding-left: 0;
    }
  }
}

.pipeline-designer-transformations {
  .datacater-grid-container {
    height: calc(100vh - 227px - 3.5rem);
  }
}

.datacater-pipeline-with-join {
  .datacater-source-logo {
    display: inline;
  }

  .datacater-source-id {
    display: inline;
  }
}
